<template>
  <div>
    <div class="basic-title">编码参数</div>
    <el-form-item label="模式">
      <el-select v-model="formData.encodeModel" style="width: 16vw">
        <el-option label="不控制" :value="0"></el-option>
        <el-option label="VBR" :value="1"></el-option>
        <el-option label="CBR" :value="2"></el-option>
        <el-option label="CQ" :value="3"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="码率/质量">
      <el-input
        v-model="formData.bitrate"
        placeholder="请输入码率"
        style="width: 16vw"
        type="number"
        @blur="
          () => {
            if (!formData.bitrate || formData.bitrate < 0) {
              formData.bitrate = 0
            }
          }
        "
      ></el-input>
    </el-form-item>
    <el-form-item label="FPS">
      <el-input
        v-model="formData.fps"
        placeholder="请输入FPS"
        style="width: 16vw"
        type="number"
        @blur="
          () => {
            if (!formData.fps || formData.fps < 0) {
              formData.fps = 0
            }
          }
        "
      ></el-input>
    </el-form-item>
    <el-divider></el-divider>
    <div class="basic-title">前置摄像头</div>
    <el-form-item label="相机角度">
      <el-select
        v-model="formData.frontCameraConfig.rotate"
        style="width: 16vw"
      >
        <el-option label="0°" :value="0"></el-option>
        <el-option label="90°" :value="90"></el-option>
        <el-option label="180°" :value="180"></el-option>
        <el-option label="270°" :value="270"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="垂直翻转">
      <el-select
        v-model="formData.frontCameraConfig.flipVertical"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="水平翻转">
      <el-select
        v-model="formData.frontCameraConfig.flipHorizontal"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <div class="sub-title">回调参数</div>
    <el-form-item label="相机角度">
      <el-select
        v-model="formData.frontCameraConfig.callbackRotate"
        style="width: 16vw"
      >
        <el-option label="0°" :value="0"></el-option>
        <el-option label="90°" :value="90"></el-option>
        <el-option label="180°" :value="180"></el-option>
        <el-option label="270°" :value="270"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="垂直翻转">
      <el-select
        v-model="formData.frontCameraConfig.callbackFlipVertical"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="水平翻转">
      <el-select
        v-model="formData.frontCameraConfig.callbackFlipHorizontal"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-divider></el-divider>
    <div class="basic-title">后置摄像头</div>
    <el-form-item label="相机角度">
      <el-select v-model="formData.backCameraConfig.rotate" style="width: 16vw">
        <el-option label="0°" :value="0"></el-option>
        <el-option label="90°" :value="90"></el-option>
        <el-option label="180°" :value="180"></el-option>
        <el-option label="270°" :value="270"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="垂直翻转">
      <el-select
        v-model="formData.backCameraConfig.flipVertical"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="水平翻转">
      <el-select
        v-model="formData.backCameraConfig.flipHorizontal"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <div class="sub-title">回调参数</div>
    <el-form-item label="相机角度">
      <el-select
        v-model="formData.backCameraConfig.callbackRotate"
        style="width: 16vw"
      >
        <el-option label="0°" :value="0"></el-option>
        <el-option label="90°" :value="90"></el-option>
        <el-option label="180°" :value="180"></el-option>
        <el-option label="270°" :value="270"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="垂直翻转">
      <el-select
        v-model="formData.backCameraConfig.callbackFlipVertical"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="水平翻转">
      <el-select
        v-model="formData.backCameraConfig.callbackFlipHorizontal"
        style="width: 16vw"
      >
        <el-option label="关闭" :value="0"></el-option>
        <el-option label="打开" :value="1"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        encodeModel: 0,
        bitrate: '',
        fps: '',
        frontCameraConfig: {
          rotate: 0,
          flipVertical: 0,
          flipHorizontal: 0,
          callbackRotate: 0,
          callbackFlipVertical: 0,
          callbackFlipHorizontal: 0
        },
        backCameraConfig: {
          rotate: 0,
          flipVertical: 0,
          flipHorizontal: 0,
          callbackRotate: 0,
          callbackFlipVertical: 0,
          callbackFlipHorizontal: 0
        }
      }
    }
  },
  mounted() {
    Object.assign(this.formData, this.value)
  },
  watch: {
    value: {
      handler(val) {
        Object.assign(this.formData, val)
      },
      deep: true
    },
    formData: {
      handler() {
        this.$emit('input', {
          ...this.formData,
          bitrate: Number(this.formData.bitrate),
          fps: Number(this.formData.fps)
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-title {
  padding-left: 32px;
  margin-bottom: 20px;
}

.basic-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
