<template>
  <el-dialog
    :title="record.versionName + '(' + record.versionCode + ')的升级描述'"
    :visible.sync="visible"
    width="30%"
  >
    <div>
      中文描述:
      <br />
      <br />
      <el-input
        v-if="edit"
        type="textarea"
        :rows="4"
        v-model="model.chineseDesc"
        placeholder="请输入内容"
      ></el-input>
      <p v-else>{{ record.chineseDesc || '-' }}</p>
    </div>
    <el-divider></el-divider>
    <div>
      英文描述:
      <br />
      <br />
      <el-input
        v-if="edit"
        type="textarea"
        :rows="4"
        v-model="model.englishDesc"
        placeholder="请输入内容"
      ></el-input>
      <p v-else>{{ record.englishDesc || '-' }}</p>
    </div>
    <el-divider></el-divider>
    <div>
      繁体描述:
      <br />
      <br />
      <el-input
        v-if="edit"
        type="textarea"
        :rows="4"
        v-model="model.traditionalDesc"
        placeholder="请输入内容"
      ></el-input>
      <p v-else>{{ record.traditionalDesc || '-' }}</p>
    </div>
    <span v-if="edit" slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="sure">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      record: {},
      model: {
        chineseDesc: '',
        englishDesc: '',
        traditionalDesc: ''
      },
      visible: false,
      edit: false
    }
  },
  methods: {
    show(record, edit) {
      this.record = record
      this.model.chineseDesc = record.chineseDesc
      this.model.englishDesc = record.englishDesc
      this.model.traditionalDesc = record.traditionalDesc
      this.visible = true
      this.edit = edit
    },
    hide() {
      this.visible = false
    },
    async sure() {
      const res = await this.$serve.publishVersionRecordUpdate({
        data: {
          recordId: this.record.recordId,
          ...this.model
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.record.chineseDesc = this.model.chineseDesc
      this.record.englishDesc = this.model.englishDesc
      this.record.traditionalDesc = this.model.traditionalDesc
      this.visible = false
      this.$message.success('修改成功')
      this.$emit('success')
    }
  }
}
</script>
