<template>
  <el-dialog
    title="推送范围"
    :visible.sync="dialogVisible"
    width="20%"
    @close="hide"
  >
    <el-form :model="formData" label-width="0" ref="ruleForm">
      <el-form-item>
        <el-radio-group v-model="formData.pushRange">
          <el-radio label="all">全部</el-radio>
          <el-radio label="part">部分推送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="formData.pushRange === 'part'"
        prop="orderIds"
        :rules="[{ required: true, message: '请输入序列号', trigger: 'blur' }]"
      >
        <el-input
          type="textarea"
          :rows="3"
          v-model="formData.orderIds"
          placeholder="请输入需要推送设备的序列号，每行输入一个序列号"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="hide">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const DefaultData = {
  pushRange: 'all',
  orderIds: ''
}
export default {
  data() {
    return {
      dialogVisible: false,
      formData: {
        pushRange: 'all',
        orderIds: ''
      }
    }
  },
  methods: {
    show(item) {
      this.dialogVisible = true
      console.log(111, item)
    },
    hide() {
      this.dialogVisible = false
      Object.assign(this.formData, DefaultData)
      this.$refs.ruleForm.clearValidate()
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$message({
            type: 'success',
            message: '推送成功！'
          })
          this.hide()
          this.$emit('success')
        }
      })
    }
  }
}
</script>
