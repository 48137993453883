var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__version-records"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.tabClick},model:{value:(_vm.model.publishType),callback:function ($$v) {_vm.$set(_vm.model, "publishType", $$v)},expression:"model.publishType"}},[_c('el-tab-pane',{attrs:{"label":"全部","name":"0"}}),_c('el-tab-pane',{attrs:{"label":"内测","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"线上","name":"2"}})],1),_c('y-list-page',{ref:"listPageRef",attrs:{"pro-form-style":{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },"form-style":{ textAlign: 'right' },"inline":"","label-width":"100px","model":_vm.model,"fields":_vm.fields,"submitter":{
      submitText: '搜索',
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    },"columns":_vm.columns,"fetch":_vm.fetchFn},scopedSlots:_vm._u([{key:"form-before-addon",fn:function(){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("发布新版本")])]},proxy:true}])}),_c('desc-dialog',{ref:"descDialogRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }