var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"page-app-configuration-add"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"inline":true,"label-width":"140px"}},[_c('div',{staticClass:"basic-title"},[_vm._v("基础信息")]),_c('el-form-item',{attrs:{"label":"设备渠道","prop":"deviceChannel","rules":[
        { required: true, message: '请选择设备渠道', trigger: 'change' }
      ]}},[_c('el-select',{staticStyle:{"width":"16vw"},attrs:{"disabled":_vm.isEdit},model:{value:(_vm.formData.deviceChannel),callback:function ($$v) {_vm.$set(_vm.formData, "deviceChannel", $$v)},expression:"formData.deviceChannel"}},_vm._l((_vm.$enums.deviceTypeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"应用名称","prop":"appName","rules":[
        { required: true, message: '请输入应用名称', trigger: 'blur' }
      ]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"placeholder":"请输入应用名称","disabled":_vm.isEdit},model:{value:(_vm.formData.appName),callback:function ($$v) {_vm.$set(_vm.formData, "appName", $$v)},expression:"formData.appName"}})],1),_c('el-form-item',{attrs:{"label":"包名","prop":"packageName","rules":[{ required: true, message: '请输入包名', trigger: 'blur' }]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"placeholder":"请输入包名","disabled":_vm.isEdit},model:{value:(_vm.formData.packageName),callback:function ($$v) {_vm.$set(_vm.formData, "packageName", $$v)},expression:"formData.packageName"}})],1),_c('el-form-item',{attrs:{"label":"版本号","prop":"version","rules":[{ required: true, message: '请输入版本号', trigger: 'blur' }]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"placeholder":"请输入版本号","disabled":_vm.isEdit},model:{value:(_vm.formData.version),callback:function ($$v) {_vm.$set(_vm.formData, "version", $$v)},expression:"formData.version"}})],1),_c('el-form-item',{attrs:{"label":"Code值","prop":"codeValue","rules":[{ required: true, message: '请输入Code值', trigger: 'blur' }]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"placeholder":"请输入Code值","disabled":_vm.isEdit},model:{value:(_vm.formData.codeValue),callback:function ($$v) {_vm.$set(_vm.formData, "codeValue", $$v)},expression:"formData.codeValue"}})],1),_c('el-form-item',{attrs:{"label":"推送范围","prop":"isDepart","rules":[
        { required: true, message: '请选择推送范围', trigger: 'change' }
      ]}},[_c('el-select',{staticStyle:{"width":"16vw"},model:{value:(_vm.formData.isDepart),callback:function ($$v) {_vm.$set(_vm.formData, "isDepart", $$v)},expression:"formData.isDepart"}},[_c('el-option',{attrs:{"label":"全部","value":0}}),_c('el-option',{attrs:{"label":"部分推送","value":1}})],1)],1),(_vm.formData.isDepart === 1)?_c('el-form-item',{attrs:{"label":"序列号","prop":"departSerials","rules":[
        { required: true, message: '请输入设备的序列号', trigger: 'blur' }
      ]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"type":"textarea","rows":3,"placeholder":"请输入需要推送设备的序列号，用英文逗号分割"},model:{value:(_vm.formData.departSerials),callback:function ($$v) {_vm.$set(_vm.formData, "departSerials", $$v)},expression:"formData.departSerials"}})],1):_vm._e(),_c('el-divider',{staticClass:"bold-divider"}),_c('div',{staticClass:"part-list"},_vm._l((_vm.formData.pageCameraConfigDTOList),function(item,index){return _c('div',{key:'part' + index,staticClass:"part-item"},[_c('el-form-item',{staticClass:"page-name-item",attrs:{"label":"页面名称","prop":("pageCameraConfigDTOList[" + index + "].pageName"),"rules":[
            { required: true, message: '请输入页面名称', trigger: 'blur' }
          ]}},[_c('el-input',{staticStyle:{"width":"16vw"},attrs:{"placeholder":"页面名称","disabled":item.isDefault === 1},model:{value:(item.pageName),callback:function ($$v) {_vm.$set(item, "pageName", $$v)},expression:"item.pageName"}}),_c('i',{staticClass:"el-icon-circle-plus-outline",on:{"click":_vm.addPage}}),(!item.isDefault)?_c('i',{staticClass:"el-icon-remove-outline",on:{"click":function($event){return _vm.deletePage(index)}}}):_vm._e()],1),_c('SettingPageItem',{key:'part-item-' + index,model:{value:(item.data),callback:function ($$v) {_vm.$set(item, "data", $$v)},expression:"item.data"}}),_c('el-divider',{staticClass:"bold-divider"})],1)}),0)],1),_c('div',{staticClass:"btn-box"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.savLoading},on:{"click":_vm.saveSetting}},[_vm._v(" 保存 ")]),_c('el-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }