<template>
  <div class="page__version-records">
    <el-tabs v-model="model.publishType" type="card" @tab-click="tabClick">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane label="内测" name="1"></el-tab-pane>
      <el-tab-pane label="线上" name="2"></el-tab-pane>
    </el-tabs>
    <y-list-page
      ref="listPageRef"
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <el-button @click="add" type="primary">发布新版本</el-button>
      </template>
    </y-list-page>

    <desc-dialog ref="descDialogRef"></desc-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import DescDialog from './_desc-dialog'

export default {
  name: 'VersionRecords',
  components: { DescDialog },
  data() {
    let { publishType } = this.$route.query
    publishType = ['1', '2'].includes(publishType) ? publishType : '0'
    return {
      model: {
        publishType,
        deviceType: '',
        wareType: '',
        sendTime: []
      },
      fields: [
        {
          type: 'select',
          formItemProps: {
            label: '型号',
            prop: 'deviceType'
          },
          attributes: {
            placeholder: '请选择型号',
            options: [
              {
                label: '全部',
                value: ''
              },
              ...this.$enums.deviceTypeList
            ]
          }
        },
        {
          type: 'select',
          formItemProps: {
            label: '安装包类型',
            prop: 'wareType'
          },
          attributes: {
            placeholder: '请选择安装包类型',
            options: [
              {
                label: '全部',
                value: ''
              },
              ...this.$enums.wareTypeList
            ]
          }
        },
        {
          type: 'date-range',
          formItemProps: {
            label: '推送时间',
            prop: 'sendTime'
          }
        }
      ],
      columns: [
        {
          label: '版本',
          fixed: true,
          render(h, record) {
            return (record.versionName || '-') + '(' + record.versionCode + ')'
          }
        },
        {
          label: 'MD5',
          prop: 'md5'
        },
        {
          label: '型号',
          prop: 'deviceType',
          render: (h, v) => {
            return this.$enumsMap.deviceTypeMap[v]
          }
        },
        {
          label: '升级描述',
          render: (h, record) => {
            return (
              <div>
                <el-button type="text" vOn:click={() => this.checkDesc(record)}>
                  详细
                </el-button>
                <el-button type="text" vOn:click={() => this.editDesc(record)}>
                  修改
                </el-button>
              </div>
            )
          }
        },
        {
          label: '安装包类型',
          prop: 'wareType',
          render(h, v) {
            return v == undefined ? '-' : this.$enumsMap.wareTypeMap[v]
          }
        },
        {
          label: '发版类型',
          prop: 'publishType',
          render(h, v) {
            if (v === 1) {
              return '内测'
            }
            if (v === 2) {
              return '线上'
            }
            return '-'
          }
        },
        {
          label: '升级范围',
          prop: 'publishScope',
          render: (h, v, record) => {
            if (v) {
              return '全部'
            }
            return (
              <div>
                <span>部分</span>
                <el-button
                  type="text"
                  vOn:click={() => this.doanloadPublishScope(record)}
                >
                  查看
                </el-button>
              </div>
            )
          }
        },
        {
          label: '推送时间',
          prop: 'publishTime',
          width: 160
        },
        {
          label: '设备数量(已升级/总数)',
          width: 100,
          render: (h, record) => {
            if (record.count) {
              return (
                <div>
                  <el-button
                    vOn:click={() => this.checkUpgrade(record)}
                    type="text"
                  >
                    {record.count}
                  </el-button>{' '}
                  / {record.totalCount}
                </div>
              )
            }
            return `${record.count} / ${record.totalCount}`
          }
        },
        {
          label: '发布状态',
          prop: 'publishStatus',
          width: 110,
          render(h, v) {
            if (v === 0) {
              return <el-tag type="danger">已停止推送</el-tag>
            } else if (v === 1) {
              return <el-tag type="success">推送中</el-tag>
            } else {
              return '-'
            }
          }
        },
        {
          label: '操作',
          prop: 'publishStatus', // 推送状态 0-停止推送 1-推送中
          width: 200,
          align: 'center',
          fixed: 'right',
          render: (h, v, record) => {
            return (
              <div>
                <el-button
                  vOn:click={() => this.downloadPackage(record)}
                  type="text"
                >
                  下载
                </el-button>
                {v === 1 && (
                  <el-button
                    vOn:click={() => this.stopAction(record)}
                    type="text"
                  >
                    停止推送
                  </el-button>
                )}
                {record.publishType === 1 && (
                  <el-button
                    vOn:click={() => this.updatePublish(record)}
                    type="text"
                  >
                    修改发布
                  </el-button>
                )}
              </div>
            )
          }
        }
      ]
    }
  },
  watch: {
    '$route.query.publishType'() {
      this.$refs.listPageRef.fetchData(this.model)
    }
  },
  methods: {
    fetchFn({ sendTime, publishType, ...params }) {
      if (sendTime?.[0]) {
        params.startTime = moment(sendTime[0]).format('YYYY-MM-DD')
        params.endTime = moment(sendTime[1]).format('YYYY-MM-DD')
      }
      if (['1', '2'].includes(publishType)) {
        params.publishType = publishType
      }
      return this.$serve
        .publishVersionRecordList({
          params
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return res
        })
    },
    add() {
      this.$router.push('/version-records/add')
    },
    checkDesc(record) {
      this.$refs.descDialogRef.show(record, false)
    },
    editDesc(record) {
      this.$refs.descDialogRef.show(record, true)
    },
    doanloadPublishScope(record) {
      this.$download({
        url: record.excelFileUrl
      })
    },
    downloadPackage(record) {
      this.$download({
        url: record.warePackageUrl
      })
    },
    async stopAction(record) {
      this.$confirm('确认停止推送吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.stop(record)
        })
        .catch(() => {})
    },
    async stop(record) {
      const res = await this.$serve.publishVersionRecordStop({
        aimid: record.recordId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('停止推送成功')
      this.$refs.listPageRef.fetchData()
    },
    tabClick(tab) {
      this.$router
        .push(
          `version-records${tab.name === '0' ? '' : `?publishType=${tab.name}`}`
        )
        .catch((err) => {
          err
        })
    },
    checkUpgrade(record) {
      // 已改成recordId查询
      // const serchLabelMap = {
      //   1: 'softWareVersion',
      //   2: 'firmWareVersion'
      // }
      // window.open(
      //   `/#/upgrade-records?searchLabel=${
      //     serchLabelMap[record.wareType]
      //   }&searchValue=${record.versionName}`
      // )
      window.open(`/#/upgrade-records?recordId=${record.recordId}`)
    },
    updatePublish(record) {
      this.$router.push(`/version-records/add/${record.recordId}`)
    }
  }
}
</script>
