<template>
  <div class="page-app-configuration">
    <div class="action-box">
      <div class="btn-box">
        <el-button type="primary" @click="add">+新增</el-button>
      </div>
      <div class="search-box">
        <el-form :inline="true" :model="formData" label-width="100px">
          <el-form-item label="应用名称">
            <el-input
              v-model="formData.appName"
              placeholder="请输入应用名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="版本号">
            <el-input
              v-model="formData.version"
              placeholder="请输入版本号"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备渠道">
            <el-select v-model="formData.deviceChannel">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in $enums.deviceTypeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div
      v-loading="tableLoading"
      element-loading-text="加载中"
      class="y-pro-table"
    >
      <el-table :data="tableData" style="width: 140%">
        <el-table-column prop="index" label="序号"></el-table-column>
        <el-table-column prop="deviceChannel" label="设备渠道">
          <template slot-scope="scope">
            {{
              $enums.deviceTypeList.find(
                (item) => item.value === scope.row.deviceChannel + ''
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="appName" label="应用名称"></el-table-column>
        <el-table-column prop="packageName" label="包名"></el-table-column>
        <el-table-column prop="version" label="版本号"></el-table-column>
        <el-table-column prop="codeValue" label="Code值"></el-table-column>
        <el-table-column prop="isPublished" label="发布状态">
          <template slot-scope="scope">
            <template v-if="scope.row.isPublished === STATUS.UNPUBLISHED">
              <el-tag type="info">未发布</el-tag>
            </template>
            <template v-if="scope.row.isPublished === STATUS.PUBLISHED">
              <el-tag type="success">已发布</el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.isPublished === STATUS.UNPUBLISHED">
              <el-button type="text" size="small" @click="toPublish(scope.row)">
                发布
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" size="small" @click="toEdit(scope.row)">
                编辑
              </el-button>
            </template>
            <template v-if="scope.row.isPublished === STATUS.PUBLISHED">
              <el-button
                type="text"
                size="small"
                @click="cancelPublish(scope.row)"
              >
                取消发布
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <PushModal ref="pushModalRef"></PushModal>
  </div>
</template>

<script>
import PushModal from './coms/push-modal'

const STATUS = {
  UNPUBLISHED: 0,
  PUBLISHED: 1
}
export default {
  name: 'AppConfiguration',
  components: {
    PushModal
  },
  data() {
    return {
      STATUS,
      formData: {
        appName: '',
        version: '',
        deviceChannel: ''
      },
      tableLoading: false,
      tableData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.tableLoading = true
      const res = await this.$serve.fetchAppList({
        params: {
          ...this.formData
        }
      })
      this.tableLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.tableData = res.map((item, index) => {
        return {
          index: index + 1,
          ...item
        }
      })
    },
    onSubmit() {
      this.getData()
    },
    add() {
      this.$router.push({ path: '/app-configuration/add' })
    },
    toPublish(item) {
      this.$confirm('是否确定发布？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$serve.updatePublishStatus({
          params: {
            id: item.id,
            isPublished: 1
          }
        })
        if (res === this.$serve.FAIL) {
          return
        }
        this.$message({
          type: 'success',
          message: '发布成功！'
        })
        this.getData()
      })
    },
    toEdit(item) {
      this.$router.push({ path: '/app-configuration/add/' + item.id })
    },
    cancelPublish(item) {
      this.$confirm('是否确定取消发布？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$serve.updatePublishStatus({
          params: {
            id: item.id,
            isPublished: 0
          }
        })
        if (res === this.$serve.FAIL) {
          return
        }
        this.$message({
          type: 'success',
          message: '取消成功！'
        })
        this.getData()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-app-configuration {
  ::v-deep {
    .status-badge {
      display: flex;
      align-items: baseline;

      .el-badge__content {
        position: relative;
        top: 3px;
        right: initial;
        order: -1;
        margin-right: 15px;
        padding-top: 0;
      }
    }

    .el-table th.el-table__cell {
      background-color: rgb(249, 250, 252);
    }
  }

  .action-box {
    display: flex;
    justify-content: space-between;
  }
}
</style>
