<template>
  <div class="page-app-configuration-add" v-loading="loading">
    <el-form
      :model="formData"
      ref="ruleForm"
      :inline="true"
      label-width="140px"
    >
      <div class="basic-title">基础信息</div>
      <el-form-item
        label="设备渠道"
        prop="deviceChannel"
        :rules="[
          { required: true, message: '请选择设备渠道', trigger: 'change' }
        ]"
      >
        <el-select
          v-model="formData.deviceChannel"
          style="width: 16vw"
          :disabled="isEdit"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in $enums.deviceTypeList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="应用名称"
        prop="appName"
        :rules="[
          { required: true, message: '请输入应用名称', trigger: 'blur' }
        ]"
      >
        <el-input
          v-model="formData.appName"
          placeholder="请输入应用名称"
          style="width: 16vw"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="包名"
        prop="packageName"
        :rules="[{ required: true, message: '请输入包名', trigger: 'blur' }]"
      >
        <el-input
          v-model="formData.packageName"
          placeholder="请输入包名"
          style="width: 16vw"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="版本号"
        prop="version"
        :rules="[{ required: true, message: '请输入版本号', trigger: 'blur' }]"
      >
        <el-input
          v-model="formData.version"
          placeholder="请输入版本号"
          style="width: 16vw"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Code值"
        prop="codeValue"
        :rules="[{ required: true, message: '请输入Code值', trigger: 'blur' }]"
      >
        <el-input
          v-model="formData.codeValue"
          placeholder="请输入Code值"
          style="width: 16vw"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="推送范围"
        prop="isDepart"
        :rules="[
          { required: true, message: '请选择推送范围', trigger: 'change' }
        ]"
      >
        <el-select v-model="formData.isDepart" style="width: 16vw">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="部分推送" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.isDepart === 1"
        label="序列号"
        prop="departSerials"
        :rules="[
          { required: true, message: '请输入设备的序列号', trigger: 'blur' }
        ]"
      >
        <el-input
          type="textarea"
          :rows="3"
          v-model="formData.departSerials"
          style="width: 16vw"
          placeholder="请输入需要推送设备的序列号，用英文逗号分割"
        ></el-input>
      </el-form-item>
      <el-divider class="bold-divider"></el-divider>
      <div class="part-list">
        <div
          class="part-item"
          v-for="(item, index) in formData.pageCameraConfigDTOList"
          :key="'part' + index"
        >
          <el-form-item
            class="page-name-item"
            label="页面名称"
            :prop="`pageCameraConfigDTOList[${index}].pageName`"
            :rules="[
              { required: true, message: '请输入页面名称', trigger: 'blur' }
            ]"
          >
            <el-input
              v-model="item.pageName"
              placeholder="页面名称"
              style="width: 16vw"
              :disabled="item.isDefault === 1"
            ></el-input>
            <i class="el-icon-circle-plus-outline" @click="addPage"></i>
            <i
              v-if="!item.isDefault"
              class="el-icon-remove-outline"
              @click="deletePage(index)"
            ></i>
          </el-form-item>
          <SettingPageItem :key="'part-item-' + index" v-model="item.data" />
          <el-divider class="bold-divider"></el-divider>
        </div>
      </div>
    </el-form>
    <div class="btn-box">
      <el-button type="primary" @click="saveSetting" :loading="savLoading">
        保存
      </el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import SettingPageItem from '../coms/setting-page-item'

const SINGLE_PAGE_DATA = {
  encodeModel: 0,
  bitrate: 0,
  fps: 0,
  frontCameraConfig: {
    rotate: 0,
    flipVertical: 0,
    flipHorizontal: 0,
    callbackRotate: 0,
    callbackFlipVertical: 0,
    callbackFlipHorizontal: 0
  },
  backCameraConfig: {
    rotate: 0,
    flipVertical: 0,
    flipHorizontal: 0,
    callbackRotate: 0,
    callbackFlipVertical: 0,
    callbackFlipHorizontal: 0
  }
}
export default {
  name: 'AppConfigurationAdd',
  components: {
    SettingPageItem
  },
  data() {
    const { id } = this.$route.params
    const isEdit = !!id
    return {
      recordId: id,
      isEdit,
      loading: false,
      savLoading: false,
      formData: {
        deviceChannel: '',
        appName: '',
        packageName: '',
        version: '',
        codeValue: '',
        isDepart: 0, // 推送范围
        departSerials: '',
        pageCameraConfigDTOList: [
          {
            pageName: 'Default',
            isDefault: 1,
            data: {
              ...JSON.parse(JSON.stringify(SINGLE_PAGE_DATA))
            }
          }
        ]
      }
    }
  },
  mounted() {
    if (this.recordId) {
      this.fetchDetail(this.recordId)
    }
  },
  methods: {
    async fetchDetail(recordId) {
      this.loading = true
      const res = await this.$serve.fetchAppList({
        params: {}
      })
      this.loading = false
      if (res === this.$serve.FAIL) {
        return
      }
      const currentData = res.filter((item) => item.id === recordId)[0]
      this.formData.deviceChannel = currentData.deviceChannel + ''
      this.formData.appName = currentData.appName
      this.formData.packageName = currentData.packageName
      this.formData.version = currentData.version
      this.formData.codeValue = currentData.codeValue
      this.formData.isDepart = currentData.isDepart
      this.formData.departSerials = currentData.departSerials
      this.formData.pageCameraConfigDTOList =
        currentData.pageCameraConfigDTOList.map(
          ({ pageName, isDefault, ...rest }) => {
            return {
              pageName: pageName,
              isDefault: isDefault,
              data: {
                ...rest
              }
            }
          }
        )
    },
    saveSetting() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const params = {
            id: this.recordId,
            deviceChannel: this.formData.deviceChannel,
            appName: this.formData.appName,
            packageName: this.formData.packageName,
            version: this.formData.version,
            codeValue: this.formData.codeValue,
            isDepart: this.formData.isDepart
          }
          // 部分推送
          if (this.formData.isDepart === 1) {
            params.departSerials = this.formData.departSerials
          }
          params.pageCameraConfigDTOList =
            this.formData.pageCameraConfigDTOList.map((item) => {
              return {
                pageName: item.pageName,
                isDefault: item.isDefault,
                ...item.data
              }
            })
          this.savLoading = true
          if (this.recordId) {
            const res = await this.$serve.updateApp({
              data: params
            })
            this.savLoading = false
            if (res === this.$serve.FAIL) {
              return
            }
          } else {
            const res = await this.$serve.addApp({
              data: params
            })
            this.savLoading = false
            if (res === this.$serve.FAIL) {
              return
            }
          }
          this.$message({
            type: 'success',
            message: '保存成功！'
          })
          this.$router.go(-1)
        }
      })
    },
    addPage() {
      this.formData.pageCameraConfigDTOList.push({
        pageName: '',
        isDefault: 0,
        data: {
          ...JSON.parse(JSON.stringify(SINGLE_PAGE_DATA))
        }
      })
    },
    deletePage(index) {
      if (this.formData.pageCameraConfigDTOList.length === 1) return
      this.formData.pageCameraConfigDTOList.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.page-app-configuration-add {
  ::v-deep {
    .el-descriptions-item__label {
      line-height: 40px;
    }

    .bold-divider {
      height: 4px;
    }

    .page-name-item {
      display: flex;

      .el-form-item__label {
        font-size: 20px;
        font-weight: 600;
      }

      .el-input {
        margin-right: 10px;
      }

      .el-form-item__content {
        display: flex;
        align-items: center;
      }

      i {
        font-size: 28px;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }

        &.el-icon-circle-plus-outline {
          margin-right: 8px;
        }

        &.el-icon-remove-outline {
          color: red;
        }
      }
    }
  }

  .basic-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .btn-box {
    button {
      width: 140px;
    }

    margin-top: 30px;
    text-align: center;
  }
}
</style>
