<template>
  <div class="page__version-records__add">
    <y-form
      style="width: 100%"
      label-width="140px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import Upload from '@/components/aws-upload'
import SparkMD5 from 'md5-util'
import { Loading } from 'element-ui'

const getFileName = function (urlStr) {
  const index = urlStr.lastIndexOf('/')
  const fullname = urlStr.substring(index + 1)
  if (!fullname.includes('_')) {
    return fullname
  }
  const [, name] = fullname.split('_')
  return decodeURIComponent(name)
}

export default {
  name: 'VersionRecordsAdd',
  components: {
    Upload
  },
  data() {
    const { id } = this.$route.params
    const validatePackageName = (rule, value, callback) => {
      if (
        this.model.deviceType === '6' &&
        this.model.wareType === '1' &&
        value === ''
      ) {
        callback(new Error('请选择包名'))
      }
    }
    return {
      recordId: id,
      model: {
        deviceType: '',
        versionCode: '',
        versionName: '',
        md5: '',
        publishType: undefined,
        chineseDesc: '',
        englishDesc: '',
        traditionalDesc: '',
        publishScope: '',
        wareType: '',
        excelFile: null,
        warePackageFile: null,
        necessaryVersion: 0,
        serialNoListPrefix: '',
        packageName: ''
      },
      fields: [
        {
          type: 'select',
          formItemProps: {
            style: 'width: 460px',
            label: '设备型号',
            prop: 'deviceType'
          },
          attributes: {
            placeholder: '请选择设备型号',
            style: 'width: 100%',
            options: this.$enums.deviceTypeList,
            disabled: id ? true : false
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '安装包',
            prop: 'wareType'
          },
          attributes: {
            placeholder: '请选择安装包',
            options: [
              {
                label: '1',
                render(h) {
                  return 'APK'
                }
              },
              {
                label: '2',
                render(h) {
                  return '固件'
                }
              }
            ],
            disabled: id ? true : false
          }
        },
        {
          formItemProps: {
            label: '',
            prop: 'packageName'
          },
          render: (h, prop, field, model) => {
            if (!model.wareType) {
              return null
            }
            if (model.wareType === '2') {
              return null
            }
            // yoloBoxUltra
            if (model.deviceType !== '6') {
              return null
            }
            return (
              <el-select
                key={model.wareType}
                v-model={model[prop]}
                disabled={id ? true : false}
                placeholder="请选择包名"
              >
                <el-option
                  label="桌面"
                  value="com.yololiv.launcher"
                ></el-option>
                <el-option label="精灵" value="com.yunxi.sprite"></el-option>
              </el-select>
            )
          }
        },
        {
          formItemProps: {
            label: '',
            prop: 'warePackageFile'
          },
          render: (h, prop, field, model) => {
            if (!model.wareType) {
              return null
            }
            let suffix, mime_types
            if (model.wareType === '1') {
              suffix = 'apk'
              mime_types = [{ title: 'apk files', extensions: 'apk' }]
            } else if (model.wareType === '2') {
              suffix = 'zip'
              mime_types = [{ title: 'zip files', extensions: 'zip' }]
            }
            return (
              <Upload
                disabled={id ? true : false}
                key={model.wareType}
                v-model={model[prop]}
                suffix={suffix}
                filters={{
                  mime_types,
                  max_file_size: `${3 * 1024}M`
                }}
                random={false}
                before-upload={this.beforeWarePackageFileUpload}
                vOn:change={this.wareTypeChange}
              >
                <template slot="addon">
                  <p style="color: #666">
                    文件命名必须以格式为「 版本名称(版本号) 」命名, 如
                    YoloLivPro-1.0.6(106)
                  </p>
                </template>
              </Upload>
            )
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: 'MD5',
            prop: 'md5'
          },
          attributes: {
            placeholder: '自动填充',
            disabled: true
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '版本号',
            prop: 'versionCode'
          },
          attributes: {
            placeholder: '自动填充',
            maxlength: '50',
            showWordLimit: true,
            disabled: true
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '版本名称',
            prop: 'versionName'
          },
          attributes: {
            placeholder: '自动填充',
            maxlength: '50',
            showWordLimit: true,
            disabled: true
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            style: 'width: 460px',
            label: '中文升级描述',
            prop: 'chineseDesc'
          },
          attributes: {
            rows: 4,
            placeholder: '请输入中文升级描述',
            maxlength: '2000',
            showWordLimit: true,
            disabled: id ? true : false
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            style: 'width: 460px',
            label: '英文升级描述',
            prop: 'englishDesc'
          },
          attributes: {
            rows: 4,
            placeholder: '请输入英文升级描述',
            maxlength: '2000',
            showWordLimit: true,
            disabled: id ? true : false
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            style: 'width: 460px',
            label: '繁体升级描述',
            prop: 'traditionalDesc'
          },
          attributes: {
            rows: 4,
            placeholder: '请输入繁体描述',
            maxlength: '2000',
            showWordLimit: true,
            disabled: id ? true : false
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '发版类型',
            prop: 'publishType'
          },
          events: {
            change: (v) => {
              this.model.publishScope = ''
            }
          },
          attributes: {
            placeholder: '请选择发版类型',
            options: [
              {
                label: '1',
                render(h) {
                  return '内测'
                }
              },
              {
                label: '2',
                // disabled: id ? true : false,
                render(h) {
                  return '线上'
                }
              }
            ]
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '推送范围',
            prop: 'publishScope'
          },
          render: (h, prop, field, model) => {
            return (
              <el-radio-group v-model={model[prop]}>
                <el-radio
                  // disabled={
                  //   model.publishType === undefined || model.publishType === '1'
                  // }
                  label="1"
                >
                  全部设备
                </el-radio>
                <el-radio
                  // disabled={
                  //   model.publishType === undefined || model.publishType === '2'
                  // }
                  label="0"
                >
                  部分设备
                </el-radio>
              </el-radio-group>
            )
          }
        },
        {
          formItemProps: {
            label: '',
            prop: 'excelFile'
          },
          render: (h, prop, field, model) => {
            if (model.publishScope === '0') {
              return (
                <Upload
                  v-model={model[prop]}
                  suffix="xlsx"
                  filters={{
                    mime_types: [{ title: 'xlsx files', extensions: 'xlsx' }],
                    max_file_size: '200M'
                  }}
                >
                  <template slot="addon">
                    <el-button type="text" vOn:click={this.download}>
                      下载模板
                    </el-button>
                  </template>
                </Upload>
              )
            }
            return null
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '是否为必须的版本',
            prop: 'necessaryVersion'
          },
          attributes: {
            placeholder: '请选择',
            options: [
              {
                label: 0,
                render(h) {
                  return '否'
                }
              },
              {
                label: 1,
                // disabled: id ? true : false,
                render(h) {
                  return '是'
                }
              }
            ]
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            style: 'width: 460px',
            label: '序列号前缀',
            prop: 'serialNoListPrefix'
          },
          attributes: {
            rows: 4,
            placeholder: '小写字母，多个用英文逗号分隔',
            disabled: id ? true : false
          }
        }
      ],
      rules: {
        deviceType: [
          { required: true, message: '请选择设备型号', trigger: 'change' }
        ],
        versionCode: [
          { required: true, message: '请输入版本号', trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: '请输入版本名称', trigger: 'blur' }
        ],
        // packageName: [{ validator: validatePackageName, trigger: 'change' }],
        MD5: [{ required: true, message: '请输入MD5', trigger: 'blur' }],
        chineseDesc: [
          { required: true, message: '请输入中文升级描述', trigger: 'blur' }
        ],
        englishDesc: [
          { required: true, message: '请输入英文升级描述', trigger: 'blur' }
        ],
        traditionalDesc: [
          { required: true, message: '请输入繁体升级描述', trigger: 'blur' }
        ],
        publishType: [
          { required: true, message: '请选择发版类型', trigger: 'change' }
        ],
        publishScope: [
          { required: true, message: '请选择推送范围', trigger: 'change' }
        ],
        wareType: [
          { required: true, message: '请选择安装包', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.recordId) {
      this.fetchDetail(this.recordId)
    }
  },
  methods: {
    async fetchDetail(aimid) {
      const res = await this.$serve.publishVersionRecordGet({
        aimid
      })
      if (res === this.$serve.FAIL || !res) {
        return
      }
      const model = {
        deviceType: res.deviceType + '',
        versionCode: res.versionCode,
        versionName: res.versionName,
        // publishType: '1',
        publishType: res.publishType + '',
        publishScope: res.publishScope + '',
        // publishScope: '0',
        wareType: res.wareType + '',
        md5: res.md5,
        chineseDesc: res.chineseDesc,
        englishDesc: res.englishDesc,
        traditionalDesc: res.traditionalDesc,
        necessaryVersion: res.necessaryVersion,
        serialNoListPrefix: res.serialNoListPrefix,
        packageName: res.packageName
      }
      if (res.excelFileUrl) {
        model.excelFile = {
          name: getFileName(res.excelFileUrl),
          url: res.excelFileUrl
        }
      }
      if (res.warePackageUrl) {
        model.warePackageFile = {
          name: getFileName(decodeURIComponent(res.warePackageUrl)),
          url: res.warePackageUrl
        }
      }
      this.model = model
    },
    async download() {
      // 后端获取模板oss地址
      const res = await this.$serve.publishVersionRecordDownloadTemplate()
      if (res === this.$serve.FAIL) {
        return
      }
      this.$download({
        url: res
      })
    },
    checkFilename(file) {
      const reg = /^[^()]+\([^()]+\)$/
      const lastDotIndex = file.name.lastIndexOf('.')
      const fileName = file.name.slice(0, lastDotIndex)
      return reg.test(fileName)
    },
    checkBlank(file) {
      const lastDotIndex = file.name.lastIndexOf('.')
      const fileName = file.name.slice(0, lastDotIndex)
      return fileName.split(' ').length > 1
    },
    beforeWarePackageFileUpload(file) {
      const blank = this.checkBlank(file)
      if (blank) {
        this.$message.error('文件命名不能包含空格')
        return false
      }
      const pass = this.checkFilename(file)
      if (!pass) {
        this.$message.error('文件上传命名格式不准确')
      }
      return pass
    },
    getFileNames(file) {
      const lastDotIndex = file.name.lastIndexOf('.')
      const fileName = file.name.slice(0, lastDotIndex)
      if (fileName.split('(').length > 2 || fileName.split(')').length > 2) {
        return
      }
      const reg = /\(([^()]+?)\)/
      const versionCode = fileName.match(reg)?.[1]
      const versionName = fileName.replace(reg, function () {
        return ''
      })
      return [versionCode, versionName]
    },
    autoFill(file) {
      const versionData = this.getFileNames(file)
      if (versionData.some((item) => !item)) {
        return
      }
      this.model.versionCode = versionData[0]
      this.model.versionName = versionData[1]
    },
    wareTypeChange(file) {
      this.autoFill(file)
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '正在计算MD5, 请稍后...'
      })
      SparkMD5.file(file.getNative(), (md5) => {
        //如果文件读取失败，md5为null
        this.model.md5 = md5
        loadingInstance.close()
      })
    },
    async submit({
      excelFile,
      warePackageFile,
      publishScope,
      wareType,
      versionName,
      versionCode,
      ...data
    }) {
      let suffix
      if (publishScope === '0') {
        if (!excelFile) {
          this.$message.error('请上传部分设备推送文件')
          return
        }
        data.excelFileUrl = excelFile.url
      }
      if (data.deviceType === '6' && wareType === '1' && !data.packageName) {
        this.$message.error('请选择包名')
        return
      }
      if (!warePackageFile) {
        this.$message.error('请选择安装包上传')
        return
      }
      data.warePackageUrl = warePackageFile.url
      if (wareType === '1') {
        suffix = '.apk'
      } else if (wareType === '2') {
        suffix = '.zip'
      }

      if (`${versionName}(${versionCode})${suffix}` !== warePackageFile.name) {
        this.$message.error('版本名称和上传安装包文件名必须一致')
        return
      }

      if (this.recordId && data.publishType === '1') {
        data.id = this.recordId
        data.isCopy = 1
      } else {
        data.isCopy = 0
      }

      const params = {
        ...data,
        publishScope,
        wareType,
        versionName,
        versionCode
      }

      if (data.publishType === '2') {
        this.$confirm('确认发布到线上吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.add(params)
          })
          .catch(() => {})

        return
      }
      this.add(params)
    },
    async add(data) {
      const res = await this.$serve.publishVersionRecordAdd({
        data
      })

      if (res === this.$serve.FAIL) {
        return
      }

      this.$message.success('发布成功')
      this.$router.push('/version-records')
    },
    reset() {
      this.$router.push('/version-records')
    }
  }
}
</script>

<style scoped lang="scss"></style>
