<template>
  <div class="component__aws-upload">
    <div class="component__aws-upload-action">
      <el-button :disabled="disabled" ref="uploadRef">上传文件</el-button>
      <div class="component__aws-upload-addon">
        <slot name="addon"></slot>
      </div>
    </div>
    <div v-if="!!file" class="component__aws-upload-name">
      <a
        class="el-button--text"
        :href="file.url"
        download="发版模板"
        target="_blank"
      >
        {{ file.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    suffix: String,
    filters: Object,
    file: Object,
    random: {
      type: Boolean,
      default: true
    },
    beforeUpload: Function
  },
  model: {
    prop: 'file',
    event: 'change'
  },
  mounted() {
    this.initUploader()
  },
  methods: {
    initUploader() {
      new this.$AwsUploader({
        browse_button: this.$refs.uploadRef.$el,
        suffix: this.suffix,
        filters: this.filters,
        random: this.random,
        BeforeUpload: this.beforeUpload,
        UploadComplete: (_, file) => {
          this.$emit('change', file)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.component__aws-upload {
  .el-button {
    margin-right: 10px;
  }
  &-action {
    display: flex;
    align-items: center;
  }
  &-addon {
    a {
      text-decoration: none;
      color: #409eff;
    }
  }
  &-name {
    color: #666;
  }
}
</style>
